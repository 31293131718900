import { usePageTitle } from '@/store'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/settings')({
  component: Settings
})

function Settings(){
  usePageTitle('设置')
  return <div>Settings</div>
}