import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { globalStore } from "@/store";
import { useStore } from "@tanstack/react-store";
import { useCallback } from "react";

export function ConfirmDialog() {
  const confirmation = useStore(globalStore, (s) => s.confirmation);
  const dismiss = useCallback(() => {
    globalStore.setState((state) => ({
      ...state,
      confirmation: undefined,
    }));
  }, []);
  return (
    <AlertDialog open={!!confirmation}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>请确认</AlertDialogTitle>
          <AlertDialogDescription>
            {confirmation?.message}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel
            onClick={() => {
              dismiss();
            }}
          >
            取消
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={() => {
              dismiss();
              confirmation?.action();
            }}
          >
            继续
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
