import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { globalStore } from "@/store";
import { useStore } from "@tanstack/react-store";

export function ProgressDialog() {
  const progressMessage = useStore(globalStore, (s) => s.progressMessage);
  return (
    <Dialog open={!!progressMessage}>
      <DialogContent
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        hideClose
      >
        <DialogHeader>
          <DialogTitle>请稍后，正在处理中</DialogTitle>
          <DialogDescription>...</DialogDescription>
        </DialogHeader>
        <div className="min-w-56 p-4 ">{progressMessage}</div>
      </DialogContent>
    </Dialog>
  );
}
