import { Button } from "@/components/ui/button";
import { useAPI, usePageTitle } from "@/store";
import { useNavigate } from "@tanstack/react-router";
import { FieldApi, FieldValidators, useForm } from "@tanstack/react-form";
import { Check, ChevronLeft, CircleAlert, Loader } from "lucide-react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn, err2str, transformXHSImage } from "@/lib/utils";
import { DatetimePicker } from "@/components/ui/datetime-picker";
import { useMutation } from "@tanstack/react-query";
import { MissionFormValue, MissionModel } from "@/api";
import { useCallback, useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { LinkButton } from "@/components/ui/buttons";
import { isBefore } from "date-fns";
import { createImage } from "@/lib/image";

const stages = [
  "检查任务数据格式",
  "抓取帖子信息",
  "抓取帖子图片",
  "提交任务",
  "推送新任务通知",
];
type FormValue = Omit<MissionFormValue, "cover">;
type SchemaKey = keyof FormValue;
type SchemaItem = {
  label: string;
  fieldType?: "text" | "longtext" | "timestamp" | "select";
  options?: string[];
  validators?: FieldValidators<FormValue, SchemaKey>;
  tip?: string;
  visibleFn?: (values: FormValue) => boolean;
};
const schema: {
  [key in SchemaKey]: SchemaItem;
} = {
  platform: { label: "平台", fieldType: "select", options: ["小红书"] },
  target: { label: "目标类型", fieldType: "select", options: ["评论", "发帖"] },
  available_until: {
    label: "下架时间",
    fieldType: "timestamp",
    validators: {
      onChange: ({ value }) => {
        if (isBefore(value, new Date())) {
          return "不能早于当前时间";
        }

        if (!value) {
          return "不能为空";
        }
        return undefined;
      },
    },
  },
  expired_at: {
    label: "结束时间",
    fieldType: "timestamp",
    validators: {
      onChange: ({ value }) => {
        if (isBefore(value, new Date())) {
          return "不能早于当前时间";
        }

        if (!value) {
          return "不能为空";
        }
        return undefined;
      },
    },
  },
  title: {
    label: "标题",
    validators: {
      onChange: ({ value }) => {
        if (!value) {
          return "不能为空";
        }
        return undefined;
      },
    },
  },
  description: {
    label: "任务要求",
    fieldType: "longtext",
    validators: {
      onChange: ({ value }) => {
        if (!value) {
          return "不能为空";
        }
        return undefined;
      },
    },
  },
  link: {
    label: "链接",
    tip: "如:https://www.xiaohongshu.com/explore/xxxxxxxxxx",
    validators: {
      onChangeListenTo: ["target"],
      onChange: ({ value, fieldApi }) => {
        if (fieldApi.form.getFieldValue("target") === "发帖") {
          return undefined;
        }

        if (!value) {
          return "不能为空";
        }
        return undefined;
      },
    },
    visibleFn: (values) => {
      return values.target === "评论";
    },
  },
};

type Props = {
  data?: MissionModel;
};

export function UpsertMission({ data }: Props) {
  usePageTitle(data ? "编辑任务" : "创建任务", [
    { label: "任务管理", path: "/home" },
  ]);

  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const defaultValues = data
    ? data
    : {
        platform: "小红书",
        title: "",
        target: "评论",
        description: "",
        link: "",
        available_until: "",
        expired_at: "",
      };
  const api = useAPI();
  const toast = useToast();
  const { mutate: fetchPost } = useMutation({
    mutationFn: async (link: string) => {
      const match = link.match(
        "https://www.xiaohongshu.com/explore/([0-9a-z]+?)\\??.*"
      );
      if (!match) {
        throw new Error("链接格式不正确");
      }
      const target_id = match[1];
      const url = `https://www.xiaohongshu.com/explore/${target_id}`;
      const note = await api.getNote(target_id);
      if (note) {
        return {
          url,
          coverImage: transformXHSImage(note.content.image_pre),
          target_id,
        };
      }
      const coverImage = await createImage(200, 200);
      if (!coverImage) {
        throw new Error("封面生成失败");
      }
      setStage(2);
      return { url, coverImage, target_id };
    },
  });
  const { mutate: send } = useMutation({
    mutationKey: ["create-mission"],
    mutationFn: async (values: FormData) => {
      if (data) {
        return await api
          .collection<MissionModel>("missions")
          .update(data.id, values);
      }
      return await api.collection<MissionModel>("missions").create(values);
    },
  });
  const [stage, setStage] = useState(0);
  const [result, setResult] = useState<MissionModel>();
  const [useRandImage, setUseRandImage] = useState(false);

  const handleSend = useCallback(
    async (formData: FormData) => {
      setStage(3);
      send(formData, {
        onSuccess(result) {
          setResult(result);
          toast.toast({
            title: data ? "编辑成功" : "创建成功",
          });
          setStage(5); // TODO: 推送消息
        },
        onError(err) {
          console.log(err);
          setErrMsg(`发生错误：${err2str(err)}`);
          setStage(0);
        },
      });
    },
    [data, send, toast]
  );
  const { handleSubmit, Field, Subscribe } = useForm<FormValue>({
    defaultValues,
    onSubmit: async (values) => {
      if (values.value.expired_at < values.value.available_until) {
        setErrMsg("结束时间不能早于下架时间");
        return;
      }
      setStage(1);

      const formData = new FormData();
      Object.entries(values.value).forEach(([k, v]) => {
        formData.set(k, v);
      });

      if (values.value.link != data?.link) {
        if (values.value.target === "评论") {
          fetchPost(values.value.link, {
            onSuccess({ url, coverImage, target_id }) {
              formData.set("cover", coverImage);
              formData.set("link", url);
              formData.set("target_id", target_id);
              handleSend(formData);
            },
            onError(err) {
              console.log(err);
              setErrMsg(`发生错误：${err2str(err)}`);
              setStage(0);
            },
          });
        } else {
          const coverImage = await createImage(200, 200);
          if (!coverImage) {
            throw new Error("封面生成失败");
          }
          formData.set("cover", coverImage);
          formData.set("link", "https://www.xiaohongshu.com");
          formData.set("target_id", "");
          handleSend(formData);
        }
      } else {
        handleSend(formData);
      }
    },
  });

  return (
    <div className="w-[40rem] relative p-4">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}
        className=""
      >
        <div className="flex items-center gap-4">
          <Button
            variant="outline"
            size="icon"
            className="h-7 w-7"
            onClick={() => navigate({ to: "/home" })}
          >
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">返回</span>
          </Button>
          <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
            {data ? "编辑任务" : "创建新任务"}
          </h1>

          <div className="hidden items-center gap-2 md:ml-auto md:flex">
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate({ to: "/home" });
              }}
            >
              取消
            </Button>
            <Subscribe
              selector={(state) => [state.canSubmit, state.isSubmitting]}
              children={([canSubmit, isSubmitting]) => (
                <Button size="sm" type="submit" disabled={!canSubmit}>
                  {isSubmitting ? "保存中..." : "保存"}
                </Button>
              )}
            />
          </div>
        </div>
        {errMsg && (
          <div className="p-2 text-red-500">
            <CircleAlert className="h-4 w-4 inline-block mr-2" />
            <Label className="">{errMsg}</Label>
          </div>
        )}
        <div className="mt-6 px-2">
          <label className=" block">
            <Input
              type="checkbox"
              checked={useRandImage}
              onChange={(e) => setUseRandImage(e.target.checked)}
              className="inline-block w-4 h-4 mr-2"
            />
            使用随机封面
          </label>
        </div>
        <div className="grid grid-cols-2 mt-6 px-2">
          <Subscribe
            selector={(state) => [state.values]}
            children={([values]) => {
              return Object.entries(schema).map(([originKey, schema]) => {
                const { visibleFn, validators, fieldType } = schema;
                const key = originKey as SchemaKey;
                const short =
                  fieldType === "select" || fieldType === "timestamp";
                const visible = visibleFn ? visibleFn(values) : true;
                return (
                  <div
                    className={cn(
                      "mb-2 space-y-2",
                      short ? "col-span-1" : "col-span-2",
                      { invisible: !visible }
                    )}
                  >
                    <Field
                      name={key}
                      validators={validators}
                      children={(field) => (
                        <FieldView {...schema} field={field} />
                      )}
                    />
                  </div>
                );
              });
            }}
          />
        </div>
      </form>
      <div
        className={cn(
          "absolute inset-0 bg-black/60  flex items-center justify-center",
          stage > 0 ? "" : "hidden"
        )}
      >
        <div className=" space-y-4">
          {stages.map((s, i) => {
            const on = stage > i;
            const next = stage === i;
            return (
              <div
                key={i}
                className={cn(
                  "text-gray-500",
                  on || next ? "text-white font-semibold" : ""
                )}
              >
                {!next && (
                  <Check
                    className={cn(
                      "w-6 h-6 inline-block mr-2",
                      on ? "text-green-500" : "invisible"
                    )}
                  />
                )}
                {next && (
                  <Loader className="w-6 h-6 mr-2 inline-block animate-spin" />
                )}
                {s}
              </div>
            );
          })}
          {stage >= stages.length && (
            <>
              {result?.cover && (
                <div>
                  <img
                    src={api.getFileUrl(result, result.cover, {
                      thumb: "200x0",
                    })}
                    alt=""
                    className="w-40 h-40 object-contain bg-gray-500"
                  />
                </div>
              )}
              <div className="flex items-center gap-2">
                <LinkButton to="/home" variant={"secondary"}>
                  返回首页
                </LinkButton>
                <Button
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  再发布一个
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function FieldView({
  label,
  tip,
  fieldType,
  options,
  field,
}: SchemaItem & { field: FieldApi<FormValue, SchemaKey> }) {
  return (
    <>
      <Label
        className={cn(
          "shrink-0",
          field.state.meta.errors?.length > 0 ? "text-red-500" : ""
        )}
      >
        {label}
      </Label>
      {(!fieldType || fieldType === "text") && (
        <Input
          name={field.name}
          value={field.state.value}
          onBlur={field.handleBlur}
          onChange={(e) => field.handleChange(e.target.value)}
        />
      )}
      {fieldType === "longtext" && (
        <Textarea
          rows={5}
          name={field.name}
          value={field.state.value}
          onBlur={field.handleBlur}
          onChange={(e) => field.handleChange(e.target.value)}
        />
      )}
      {fieldType === "timestamp" && (
        <DatetimePicker
          value={field.state.value}
          onValueChange={field.handleChange}
        />
      )}
      {fieldType === "select" && (
        <Select
          name={field.name}
          value={field.state.value}
          onValueChange={(value) => field.handleChange(value)}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="" />
          </SelectTrigger>
          <SelectContent>
            {options?.map((option) => (
              <SelectItem key={option} value={option}>
                {option}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )}
      {tip && <p className="text-sm text-muted-foreground">{tip}</p>}
      {field.state.meta.errors ? (
        <div>
          <em role="alert" className="text-red-500 text-xs">
            {field.state.meta.errors.join(", ")}
          </em>
        </div>
      ) : null}
    </>
  );
}
