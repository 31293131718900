import Pocketbase, {
  AuthModel,
  BaseAuthStore,
  ClientResponseError,
  LocalAuthStore,
  RecordModel,
} from "pocketbase";

export interface UserModel extends RecordModel {
  username: string;
  credits: number;
}

export interface BaseModel extends RecordModel {
  created: string;
  updated: string;
}

export interface HistoryTargetModel extends BaseModel {
  website: string;
  target_id: string;
  link: string;
}

export interface MissionFormValue {
  title: string;
  platform: string;
  target: string;
  description: string;
  link: string;
  available_until: string;
  expired_at: string;
  cover: string;
}

export interface MissionModel extends BaseModel, MissionFormValue {
  status?: "normal" | "disabled";
  target_id: string;
}

export interface ExternalAccount {
  link: string;
  website: string;
  username: string;
  avatar: string;
}

export interface ExternalAccountModel extends BaseModel, ExternalAccount {}

export interface TaskModel extends BaseModel {
  mission: string;
  user: string;
  enroll_at: string;
}

export interface TaskResultModel extends BaseModel {
  account: string;
  user: string;
  mission: string;
  screenshot: string;
  audit_result?: "ok" | "reject" | "initial";
  audit_comment: string;
  account_snapshot?: ExternalAccountModel;
}

export interface AuditRow {
  result: TaskResultModel;
  mission: MissionModel;
  user: UserModel;
  account: ExternalAccountModel;
}

export interface CreditRecordModel extends BaseModel {
  author: string;
  receiver: string;
  origin: string;
  credits: number;
  origin_info: { [key: string]: string };
}

export interface AuditRecordModel extends BaseModel {
  task: string;
  author: string;
  comment: string;
  result: TaskResultModel["audit_result"];
}

interface CommentUserInfo {
  user_id: string;
  nickname: string;
  image: string;
}

export interface CommentItem {
  id: string;
  content: string;
  time: number;
  user: CommentUserInfo;
}

export interface BaseUser {
  avatar: string;
  user_id: string;
  nickname: string;
}

export interface XHSNoteModel extends BaseModel {
  note_id: string;
  content: XHSNoteContent;
  tags?: string[];
  state: number;
}

export interface XHSNoteContent {
  id: string;
  user: BaseUser;
  title: string;
  desc: string;
  image_pre: string;
  image_default: string;
  time: number;
  interact: {
    like_count: number;
    collected_count: number;
    comment_count: number;
  };
  comments: CommentItem[];
  keywords?: string[];
}

export interface BriefXHSNoteModel extends BaseModel {
  note_id: string;
  content: Omit<XHSNoteContent, "comments">;
  state: number;
  tags?: string[];
  used?: string;
}

export class ReadOnlyAuthStore extends BaseAuthStore {
  constructor(readonly _token:string,model:AuthModel) {
    super()
    this.baseModel = model
  }

  get token(): string {
    return this._token
  }
}

export class APIClient extends Pocketbase {
  constructor(store?:BaseAuthStore) {
    super(import.meta.env.VITE_API_HOST, store?store:new LocalAuthStore());
    console.log(
      `using ${import.meta.env.MODE} with ${import.meta.env.VITE_API_HOST}`
    );
    if (!this.authStore.isValid) {
      this.authStore.clear();
    }
  }

  get user() {
    return this.authStore.model as UserModel;
  }

  get isMaster() {
    return this.user?.username === "ZhengWei";
  }

  signout() {
    this.authStore.clear();
  }

  authWithCode(code: string) {
    const recordSerice = this.collection<UserModel>("users");

    return recordSerice.client
      .send(
        `/api/taskbooze/${recordSerice.collectionIdOrName}/auth-with-code`,
        {
          query: { code },
          method: "POST",
        }
      )
      .then((responseData) => {
        const record = recordSerice.decode(responseData?.record || {});
        if (!record.id || record.role !== "admin") {
          throw new Error("invalid auth response");
        }

        recordSerice.client.authStore.save(responseData?.token, record);

        return Object.assign({}, responseData, {
          // normalize common fields
          token: responseData?.token || "",
          record: record as UserModel,
        });
      });
  }

  async getNotes(page: number, sort: string, keyword?: string) {
    return await this.collection<BriefXHSNoteModel>("v_xn_sort_like_count")
      .getList(page, 20, {
        sort,
        filter: keyword ? `keywords ~ "%${keyword}%"` : "",
      })
      .then((res) => {
        return {
          ...res,
          items: res.items.map((item) => {
            return {
              ...item,
              id: item.note_id,
            };
          }),
        };
      });
  }

  async getNote(note_id: string) {
    try {
      return await this.collection<XHSNoteModel>("xhs_notes")
        .getFirstListItem(`note_id="${note_id}"`)
    } catch (e) {
      if (e instanceof ClientResponseError) {
        if ((e as ClientResponseError).status === 404) {
          return null;
        }
      }
      throw e;
    }
  }

  async getNextAuditTask() {
    return this.collection<TaskResultModel>("task_results").getFirstListItem(
      ["screenshot != ''", "audit_result = ''"].filter((f) => !!f).join(" && "),
      {
        sort: "-created",
        expand: "mission,user,account",
      }
    );
  }
}
