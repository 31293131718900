import { createFileRoute } from '@tanstack/react-router'
import { globalStore } from '@/store'
import { MissionModel } from '@/api'
import { UpsertMission } from './upsert-mission'

export const Route = createFileRoute('/_dashboard/home/missions/$missionId')({
  component: EditMission,
  loader: ({ params:{missionId} }) => fetchMission(missionId),
  errorComponent: ({ error }) => {
    // Render an error message
    return <div>{error.message}</div>
  },
})

function fetchMission(missionId: string) {
  return globalStore.state.api.collection<MissionModel>('missions').getOne(missionId)
}

export function EditMission() {
    const data = Route.useLoaderData()
    return <UpsertMission data={data} />
}