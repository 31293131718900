import {
  BookHeart,
  BookOpenCheck,
  FileClock,
  Home,
  Package2,
  PanelLeft,
  Settings,
  Snail,
  User,
} from "lucide-react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";

import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from "@/components/ui/tooltip";
import { Link, Outlet, useNavigate } from "@tanstack/react-router";
import { globalStore, useAPI, useBreadcrumbs, useWorker } from "@/store";
import { Fragment } from "react/jsx-runtime";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useStore } from "@tanstack/react-store";
import { cn } from "@/lib/utils";

type NavMoudle = {
  label: string;
  path: string;
  icon: JSX.Element;
};

const modules: NavMoudle[] = [
  {
    label: "任务管理",
    path: "/home",
    icon: <Home className="h-5 w-5" />,
  },
  {
    label: "任务审核",
    path: "/tasks",
    icon: <BookOpenCheck className="h-5 w-5" />,
  },
  {
    label: "积分记录",
    path: "/logs",
    icon: <FileClock className="h-5 w-5" />,
  },
  {
    label: "批量处理",
    path: "/console",
    icon: <BookHeart fill="white" className="text-red-600 h-5 w-5" />,
  },
];

const masterModules: NavMoudle[] = [
  {
    label: "用户管理",
    path: "/users",
    icon: <User className="h-5 w-5" />,
  },
];

const settingModule: NavMoudle = {
  label: "设置",
  path: "/settings",
  icon: <Settings className="h-5 w-5" />,
};

export function Layout() {
  const breadcrumbs = useBreadcrumbs();
  const hideHeader = useStore(globalStore, (state) => state.hideHeader);
  const navigate = useNavigate();
  const api = useAPI();
  useWorker()
  const regularModules = api.isMaster
    ? [...modules, ...masterModules]
    : modules;
  const allModules = [...regularModules, settingModule];
  return (
    <div className="flex min-h-screen w-full flex-col bg-muted">
      <aside className="fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r bg-background sm:flex">
        <TooltipProvider>
          <nav className="flex flex-col items-center gap-4 px-2 py-4">
            <Link
              href="#"
              className="group flex h-9 w-9 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-lg font-semibold text-primary-foreground md:h-8 md:w-8 md:text-base"
            >
              <Snail className="h-5 w-5 transition-all group-hover:scale-110" />
              <span className="sr-only">Muge Inc</span>
            </Link>
            {regularModules.map((module) => (
              <Tooltip key={module.label}>
                <TooltipTrigger asChild>
                  <Link
                    to={module.path}
                    className="flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8 data-[status=active]:text-foreground data-[status=active]:bg-primary/20 data-[status=active]:font-semibold"
                  >
                    {module.icon}
                    <span className="sr-only">{module.label}</span>
                  </Link>
                </TooltipTrigger>
                <TooltipContent side="right">{module.label}</TooltipContent>
              </Tooltip>
            ))}
          </nav>
          <nav className="mt-auto flex flex-col items-center gap-4 px-2 py-4">
            <Tooltip>
              <TooltipTrigger>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <p className="flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8">
                      {settingModule.icon}
                      <span className="sr-only">{settingModule.label}</span>
                    </p>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent side="right">
                    <DropdownMenuLabel>设置</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      onClick={() => {
                        globalStore.state.api.authStore.clear();
                        navigate({ to: "/" });
                      }}
                    >
                      退出
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => {
                        navigate({ to: "/settings" });
                      }}
                    >
                      关于
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TooltipTrigger>
              <TooltipContent side="right">
                {settingModule.label}
              </TooltipContent>
            </Tooltip>
          </nav>
        </TooltipProvider>
      </aside>
      <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-14">
        <header
          className={cn(
            "sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6",
            hideHeader ? "hidden" : ""
          )}
        >
          <Sheet>
            <SheetTrigger asChild>
              <Button size="icon" variant="outline" className="sm:hidden">
                <PanelLeft className="h-5 w-5" />
                <span className="sr-only">Toggle Menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="left" className="sm:max-w-xs">
              <nav className="grid gap-6 text-lg font-medium">
                <Link
                  href="#"
                  className="group flex h-10 w-10 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-lg font-semibold text-primary-foreground md:text-base"
                >
                  <Package2 className="h-5 w-5 transition-all group-hover:scale-110" />
                  <span className="sr-only">Acme Inc</span>
                </Link>
                {allModules.map((module) => (
                  <Link
                    key={module.label}
                    to={module.path}
                    className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
                  >
                    {module.icon}
                    {module.label}
                  </Link>
                ))}
              </nav>
            </SheetContent>
          </Sheet>
          <Breadcrumb className="hidden md:flex mx-auto w-[68rem]">
            <BreadcrumbList>
              {breadcrumbs.map((crumb, index) => {
                const isLast = index === breadcrumbs.length - 1;
                return (
                  <Fragment key={index}>
                    {isLast && (
                      <BreadcrumbItem key={crumb.label}>
                        <BreadcrumbPage className="text-lg">
                          {crumb.label}
                        </BreadcrumbPage>
                      </BreadcrumbItem>
                    )}
                    {!isLast && (
                      <>
                        <BreadcrumbItem key={crumb.label}>
                          <BreadcrumbLink asChild>
                            <Link to={crumb.path}>{crumb.label}</Link>
                          </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                      </>
                    )}
                  </Fragment>
                );
              })}
            </BreadcrumbList>
          </Breadcrumb>
        </header>
        <main className=" grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          <div className="mx-auto grid flex-1 auto-rows-max gap-4 bg-background p-4">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
}
