import { createRootRoute, Outlet } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { Toaster } from "@/components/ui/toaster"
import { ProgressDialog } from '@/components/progress-dialog'
import { ConfirmDialog } from '@/components/confirm-dialog'

export const Route = createRootRoute({
  component: RootRoute,
})

function RootRoute(){

  return (
  <>
    <Outlet />
    <Toaster/>
    <ProgressDialog/>
    <ConfirmDialog/>
    {import.meta.env.DEV&&<TanStackRouterDevtools position='bottom-right'/>}
  </>
)}
