import { Store } from "@tanstack/store";
import { useStore } from "@tanstack/react-store";
import { useEffect } from "react";
import { APIClient } from "@/api";
// import { createEndpoint, fromWebWorker } from "@remote-ui/rpc";
import { Messager } from "@/worker-protocal";

import * as Comlink from "comlink"

type StoreType = {
  breadcrumbs: NavItem[];
  hideHeader: boolean;
  api: APIClient;
  progressMessage?: string;
  confirmation?: { message: string; action: () => void };
  searchHistory: string[];
  notesWorker: Worker;
};

function loadHistory(): string[] {
  const raw = localStorage.getItem("searchHistory");
  return raw ? (JSON.parse(raw) as string[]) : [];
}

export const globalStore = new Store<StoreType>({
  breadcrumbs: [],
  hideHeader: false,
  api: new APIClient(),
  searchHistory: loadHistory(),
  notesWorker: new Worker(new URL("./worker.ts", import.meta.url), {
    type: "module",
  }),
});

export type NavItem = {
  label: string;
  path?: string;
};

// if parents is false, hide header
export function usePageTitle(title: string, parents?: NavItem[] | boolean) {
  useEffect(() => {
    document.title = title;
    if (typeof parents === "boolean") {
      globalStore.setState((state) => {
        return {
          ...state,
          hideHeader: !parents,
          breadcrumbs: [{ label: title }],
        };
      });
    } else {
      globalStore.setState((state) => {
        return {
          ...state,
          hideHeader: false,
          breadcrumbs: [...(parents || []), { label: title }],
        };
      });
    }
  }, [parents, title]);
}

export function useBreadcrumbs() {
  return useStore(globalStore, (state) => state.breadcrumbs);
}

export function useAPI() {
  return useStore(globalStore, (state) => state.api);
}

export function useWorker() {
  const [worker, api] = useStore(globalStore, (state) => [
    state.notesWorker,
    state.api,
  ]);
  const endpoint = Comlink.wrap<Messager>(worker)
  return {
    worker,
    endpoint,
    initWorker:async () => {
      if (api.authStore.model) {
        return await endpoint.initial({
          token: api.authStore.token,
          model: api.authStore.model,
        });
      }
    },
  };
}

export function useProgressDialog() {
  return {
    show: (message: string) => {
      globalStore.setState((state) => {
        return {
          ...state,
          progressMessage: message,
        };
      });
    },
    dismiss: () => {
      globalStore.setState((state) => {
        return {
          ...state,
          progressMessage: undefined,
        };
      });
    },
  };
}

export function useConfirm() {
  return {
    show: (message: string, action: () => void) => {
      globalStore.setState((state) => {
        return {
          ...state,
          confirmation: { message, action },
        };
      });
    },
    dismiss: () => {
      globalStore.setState((state) => {
        return {
          ...state,
          confirmation: undefined,
        };
      });
    },
  };
}

export function useSearchHistory() {
  return {
    history: useStore(globalStore, (state) => state.searchHistory),
    append: (kw: string) => {
      if (!kw) return;
      const current = globalStore.state.searchHistory;
      // remove duplicates
      const history = current.filter((k) => k !== kw);

      history.unshift(kw);
      if (history.length > 5) history.pop();

      localStorage.setItem("searchHistory", JSON.stringify(history));
      globalStore.setState((state) => {
        return {
          ...state,
          searchHistory: history,
        };
      });
    },
  };
}
