import { Layout } from "@/routes/layout";
import { globalStore } from "@/store";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/_dashboard")({
  beforeLoad: async ({ location }) => {
    const api = globalStore.state.api;
    if (api.authStore.model) {
      return;
    }
    throw redirect({
      to: "/signin",
      replace: true,
      search: {
        redirect: location.href,
      },
    });
  },
  component: Layout,
});
