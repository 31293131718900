import { createFileRoute, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/')({
  beforeLoad: async ({ location }) => {
    throw redirect({
      to: "/home",
      replace: true,
      search: location.search,
    })
  }
})