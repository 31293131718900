import multiavatar from "@multiavatar/multiavatar";
import {Canvg} from "Canvg"

export async function createImage(width:number,height:number){
    const canvas = document.createElement('canvas');
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    const data = multiavatar(new Date().getTime().toString())
    const v = Canvg.fromString(ctx, data);

    await v.render({
        scaleHeight:height,
        scaleWidth:width,
    })

    return new Promise<Blob | null>((resolve)=>{
        canvas.toBlob((blob)=>{
            resolve(blob)
        },"image/png")
    })
}